/* VelocityLayout.scss */

.velocity-layout {
  position: relative;
  height: 100vh;
  overflow: hidden;

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .logo {
    width: auto;
    height: 75vh; /* Ajusta el tamaño del logotipo según tus preferencias */
  }

}
