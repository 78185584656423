@layer utilities {
  .perspective-1000 {
    perspective: 1000px;
  }
  .transform-style-preserve-3d {
    transform-style: preserve-3d;
  }
  .backface-hidden {
    backface-visibility: hidden;
  }
  .rotate-y-180 {
    transform: rotateY(180deg);
  }
  .duration-800 {
    transition-duration: 0.8s;
  }
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
}
